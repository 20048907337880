.box-tab {
  .tab-head {
    display: flex;
    > div {
      font-size: 18px;
      color: var(--grey-300);
      font-weight: 600;
      padding: 10px 15px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      &.active {
        border-color: var(--primary-bg);
        color: var(--primary-bg);
      }
    }
  }
  .tab-content {
    background-color: var(--page-content-bg);
    color: var(--page-content-color);
    padding: 10px 15px;
    margin: 10px  0 20px 0;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 2px var(--grey-100);
  }
}

.custom-tab {
  .tab-head {
    display: flex;
    > div {
      background-color: var(--list-bgh);
      border-bottom: 5px solid var(--list-bgh);
      padding: 10px 15px;
      cursor: pointer;
      &.active {
        background-color: var(--input-bg);
        border-color: var(--primary-bg);
      }
    }
  }
  .custom-tab .tab-content {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    padding: 10px 15px;
  }
}