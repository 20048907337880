.pagination-custom {
  color: var(--input-color);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > button {
    margin: 0 10px;
    >svg{
      font-size: 12px;
    }
  }
  .page-item {
    input {
      background-color: transparent;
      border: 1px solid var(--list-border);
      color: var(--primary-bg);
      border-radius: 5px;
      font-size: 12px;
      padding: 7px;
      text-align: center;
      width: 65px;
      margin-right: 10px;
    }
    >span{
      font-size: 14px;
    }
    button {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  span.pagination-info {
    text-align: center;
    display: block;
    font-size: 80%;
    margin-top: 2px;
  }
}