.box-btn {
  display: flex;
  justify-content: flex-end;
  .btn + .btn{
    margin-left: 10px;
  }
}
.box-telaDesenvolvimento{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 85px);
  h1{
    text-align: center;
    strong{
      display: block;
      color: var(--primary-bg);
      font-size: 28px;
    }
  }
}

.custom-tooltip{
  background-color: var(--page-content-bg);
  border: 1px solid var(--grey-100);
  padding: 10px;
  border-radius: 8px;
  .label{
      border-bottom: 1px solid var(--grey-100);
      padding-bottom: 8px;
      font-weight: 600;
  }
  .desc{
      display: flex;
      align-items: center;
      margin: 5px 0;
      span{
          display: inline-block;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          margin-right: 8px;
      } 
  }
}
.custom-legend{
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
  top: 10px;
  &.legend{
    &-left{
      justify-content: flex-start;
    }
    &-right{
      justify-content: flex-end;
    }
  }
  >div{
      display: flex;
      align-items: center;
      margin: 5px 10px 5px 0;
      span{
          display: inline-block;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          margin-right: 8px;
      } 
  }
}

.pageloadview-message{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &-erro{
    font-size: 16px;
    font-weight: 500;
  }
}