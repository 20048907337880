.box-login{
    &-frame{
        background-color: #242525;
        height: 100vh;
        overflow: hidden;
        >img{
            height: auto;
            width: 100%;
        }
    }
    &-form{
        padding: 20px;
    }
    &-logo{
        margin-bottom: 40px;
        max-width: 262px;
        >img{
            width: 100%;
        }
    }
    &-padlock{
        height: 34px;
        margin: -12px 0 20px 0;
        >img{
            height: 100%;
        }
    }
    &-info{
        margin-bottom: 16px;
        h2{
            font-size: 46px;
            font-weight: 800;
            line-height: 56px;
        }
        h4{
            font-size: 26px;
            font-weight: 800;
            line-height: 30px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-top: 20px;
        }
    }
    &-container{
        display: flex;
        margin: auto;
        flex-direction: row;
        height: 100vh;
        align-items: center;
        justify-content: space-between;
        >div{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &-content{
        max-width: 424px;
        &-senhahas{
            display: block;
            >label{
                display: block;
                &.has-success{
                    color: var(--success-bg);
                }
            }
        }
    }
    &-action{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        small{
            font-size: 11px;
        }
        >div{
            margin-top: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        >button{
            margin-top: 40px; 
        }
        &-btns{
            margin-top: 40px !important; 
            
            >button{
                width: calc(50% - 10px); 
            }
        }
    }
}


@media (max-width: 780px) {
    .box-login{
        &-frame{
            display: none !important;
        }
        &-form{
            width: 100% !important;
        }
    }
  }