.box-rankingnps{
    &-search{
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 75px;
        &-box{
            display: flex;
            align-items: center;
        }
        &-action{
            margin-right: 10px;
        }
        &-type{
            background-color: var(--grey-000);
            display: flex;
            align-items: center;
            border-radius: 5px;
            padding: 6px;
            margin-right: 15px;
            p{
                color: var(--grey-300);
                margin: 0;
            }
            button{
                padding: 3px;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 34px;
                height: 34px;
                margin-right: 10px;
                border-radius: 8px;
            }
        }
        &.rankingnps-search-ciclo{
            .form-box{
                margin: 0 10px 0 0;
                padding: 0;
                label{
                    color: var(--grey-300);
                    margin-bottom: 2px;
                }
            }
            .form-select-box{
                min-width: 120px;
                .select-selected > svg{
                    font-size: 16px;
                    margin-right: 5px;
                }
            }
            .radio-button-container{
                background-color: var(--grey-000);
                display: flex;
                align-items: center;
                border-radius: 5px;
                padding: 3px 8px;
                margin-right: 15px;
            }
            &-ciclo{
                display: flex;
                align-items: center;
            }
        }
        &.rankingnps-search-data{
            .form-box{
                margin: 0 10px 0 0;
                padding: 0;
                label{
                    color: var(--grey-300);
                    margin-bottom: 2px;
                }
                input{
                    min-height: 32px;
                    
                    &::-webkit-calendar-picker-indicator {
                        position: absolute;
                        left: 5px;
                        top: calc((100% - 20px) / 2 );
                        width: 20px;
                        height: 20px;
                        margin: 0;
                        padding: 0;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media (max-width: 779px) {
    .box-rankingnps{
        &-search{
            flex-direction: column;
            >div{
                width: 100%;
            }
            &-box{
                flex-direction: column;
                width: 100%;
                >div{
                    width: 100%;
                }
            }
            &-type{
                width: 100%;
                margin-bottom: 15px;
            }
            &-action{
                button{
                    margin-top: 20px;
                    width: 100%;
                }
            }
            &.rankingnps-search-ciclo{
                width: 100%;
                .form-box{
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
            &.rankingnps-search-data{
                width: 100%;
                .form-box{
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }
    }
}