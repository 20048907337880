.menu {
  button {
    background-color: transparent;
    border: none;
    color: var(--sidebar-color);
    cursor: pointer;
    transition: all 0.2s;
    text-align: left;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;

    &:hover,
    &.active {
      background-color: var(--background-sidebar-hc);
      color: var(--background-sidebar-h);
      transition: all 0.2s;
    }
    &.active {
      font-weight: 700;
    }
    span{
      padding: 18px 15px;
      width: 100%;
    }
    svg{
      font-size: 18px;
    }
    i{
      padding: 5px 15px 5px 5px;
      svg{
        font-size: 14px;
      }
    }
    
  }
  > div > button {
    &::before{
      content: "";
      display: inline-block;
      background-color: transparent;
      width: 4px;
      height: 32px;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
    &:hover,
    &.active {
      &::before{
        background-color: var(--primary-bg);
      }
    }
    &.open {
      &::before{
        background-color: currentColor;
      }
    }
  }
  &-submenu{
    padding-left: 25px;
    >button{
      border-left: 1px solid var(--sidebar-color);
    }
  }
}