.box-graficos-heatmap{
    table{
        border-spacing: 0;
        td{
            height: 60px;
            border: 1px solid #cdcdcd;
            border-top-width: 0;
            border-left-width: 0;
        }
    }
    .grafico-table{
        &-head{
            display: inline-block;
            margin-bottom: 15px;
            width: 300px;
            &-ico{ 
                width: 70px;
                padding: 0 10px;
                text-align: center;
                >img{
                    max-height: 45px;
                }
            }
            &-name{ 
                width: 240px;
                padding: 0 10px;
                color: var(--grey-300);
            }
        }
        &-scroll{
            overflow-x: scroll;
            display: inline-block;
            width: calc(100% - 300px);    
            padding-bottom: 5px;

        }
        &-content{
            td{
                min-width: 100px;
                border-right: 1px solid #cdcdcd;
                &:hover{
                    border: 1px solid var(--grey-200) !important;
                }
            }
        }
        &-header{
            td{
                height: 40px;
                padding: 0 10px;
                white-space: nowrap;
                text-align: center;
            }
        }
    }
}

#root-grafico-hint{
    .grafico-table-hint{
        background-color: var(--page-content-bg);
        color: var(--page-content-color);
        border: 1px solid var(--grey-100);
        font-size: 12px;
        padding: 6px;
        border-radius: 5px;
        white-space: nowrap;
        > label{
            display: block;
            margin-bottom: 2px;
            font-weight: 600;
            color: var(--primary-bg);
        }
        > span{
            display: block;
        }
    }
}