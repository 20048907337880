#root-loading {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
}
#root-loading .box-loading {
  background-color: var(--modal-bgo);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: auto;
  padding: 10px 0 20px 0;
  width: 100%;
}
#root-loading .box-load {
  /* background-color: var(--primary-bg); */
  color: var(--primary-color);
  padding: 20px 30px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#root-loading .box-load h5 {
  margin-bottom: 10px;
}

.loader-default {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--primary-bg);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.box-loading-element{
  display: flex;
  align-items: center;
  justify-content: center;
  .box-load{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


#root-loading .box-load svg.icon-pin {
  width: 60px;
  height: 60px;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.95);
  }
}
