[class*='icon-'] {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.circle-icon {
  border: 3px solid;
  border-radius: 50%;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
