.box-tab {
}
.box-tab > div {
  margin-bottom: 2px;
}
.box-tab .accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  
  background-color: var(--secondary-bgh);
  color: var(--secondary-color);
  border: 1px solid var(--secondary-bgh);
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
}
.box-tab .accordion-header h6 {
  font-size: 18px;
  font-weight: 500;
}
.box-tab > div.open .accordion-header {
  background-color: var(--secondary-bgh);
  color: var(--secondary-color);
}
.box-tab .accordion-container {
  border: 1px solid var(--secondary-bgh);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: none;
}
.box-tab .open .accordion-container {
  display: block;
}
.box-tab .accordion-content {
  padding: 10px 15px;
}

.custom-tab {
}
.custom-tab > div {
  margin-bottom: 2px;
}
.custom-tab .accordion-header {
  background-color: var(--primary-bg);
  color: var(--primary-color);
  border-radius: 4px;
  font-size: 18px;
  padding: 2px 10px;
  margin: 4px 0;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.custom-tab .accordion-header:hover {
  opacity: 0.8;
}
.custom-tab .accordion-header h6 {
  font-size: 18px;
  font-weight: 500;
}
.custom-tab .accordion-container {
  display: none;
}
.custom-tab .open .accordion-container {
  display: block;
  transform-origin: top;
  animation: expandY 0.3s ease forwards;
}
.custom-tab .accordion-content {
  padding: 10px 15px;
}

@keyframes expandY {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
