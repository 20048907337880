#box-header {
  background-color: var(--header-bg);
  color: var(--header-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: stretch;
  min-height: 30px;
  position:relative;
  box-shadow: 0px 5px 15px 1px rgb(0 0 0 / 10%);

  > div {
    padding: 0 15px;
  }
  .box-logo{
    height: 60px;
    padding: 10px 15px;

    display: flex;
    align-items: center;
    > div{
      margin-right: 10px;
      svg{
        max-width: 150px;
      }
    }
    h1{
      color: var(--header-color);
      font-size: 24px;
      font-weight: 800;
      margin: 0;
    }
    > img{
      width: auto;
      max-height: 100%;
    }
  }
  button {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0px;
    color: var(--header-menu-color);
    cursor: pointer;
    display: inline-block;
    font-size: 1.0em;
    padding: 8px 15px;
    transition: all 0.15s;
    .btFlex{
      display: flex;
      align-items: center;
    }
    svg {
      display: flex;
      font-size: 1.5em;
    }
    :hover,
    .open {
      background-color: var(--header-menu-bg);
      color: var(--header-menu-color);
    }
  }
  .box-user-info{
    display: flex;
    align-items: center;
    p{
      margin: 0;
      border-right: 1px solid var(--grey-100);
      padding: 10px 15px;
      height: 65%;
    }
    button{
      display: flex;
      align-items: center;
      padding: 15px 15px 8px 15px;
      opacity: 1;
      >svg{
        padding: 0 5px;
        font-size: 28px;
      }
      &:hover{
        color: var(--primary-bg);
        >svg{
          color: var(--primary-bg);
        }
      }
    }
  }
}

@media (min-width: 780px) {
  #box-header{
    .btn-menu {
      display: none;
    }
  }
}

@media (max-width: 779px) {
  #box-header{
    .box-user-info{
      position: absolute;
      top: 60px;
      background-color: var(--page-content-bg);
      z-index: 2;
      flex-direction: column;
      width: 100%;
      display: none;
      &.open-box{
        display: flex;
        p{
          border: none;
          margin: 15px 0 20px 0;
        }
        button{
          margin: 10px 0;
        }
      }
    }
    .box-logo h1{
      font-size: 17px;
      font-weight: 600;
    }
  }
}